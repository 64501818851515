body,
html {
  font-family: "Helvetica Neue", "Montserrat", sans-serif !important;
  overflow-x: hidden !important;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100%;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  margin-top: 0;
  margin-bottom: 15px;
}

.button-login {
  background: #214a4c;
  border-color: #214a4c;
  color: #ffffff;
}

.button,
a,
button,
input,
input[type="submit"],
select,
textarea {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

button.submit {
  background: #04d39f;
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
}

.logo-voktum {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .login-form {
    width: 360px;
  }
}
@media (max-width: 768px) {
  .login-form {
    width: 100%;
  }
}

.label-login {
  font-size: 1rem;
  color: black;
}

.btn {
  padding: 10px 20px !important;
  border-radius: 3px !important;
}

.form-group:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
textarea:focus {
  border: 1px solid #323232 !important;
  box-shadow: none !important;
  outline: 2px solid #323232 !important;
}
/* Estilos específicos para Firefox */
.form-group:-moz-focusring,
input[type="email"]:-moz-focusring,
input[type="password"]:-moz-focusring,
input[type="search"]:-moz-focusring,
input[type="text"]:-moz-focusring,
textarea:-moz-focusring {
  border: 1px solid #323232 !important;
  box-shadow: none !important;
  outline: 1px solid #323232 !important;
}

textarea.form-control {
  height: auto;
  padding: 13px;
}

.fixed-bottom {
  z-index: 998 !important;
}
.search_form-wrap .search_form-inner input.form-control {
  background: 0 0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  width: 450px;
  padding-right: 50px;
  font-size: 15px;
  box-shadow: none;
  height: 45px;
  min-width: 220px;
  padding: 0 25px;
  border-radius: 0 50px 50px 0;
}

.search-modal
  .search-wrapper
  .search-modal-content
  .search_form-wrap
  .search_form-inner
  input.form-control {
  width: 100%;
  min-width: 100%;
  border-radius: 0;
  width: 100%;
  height: 55px;
  padding: 0 15px;
  font-size: 16px;
  border: none;
  padding-right: 60px;
}

.request-box .form-control {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.05);
  vertical-align: top;
}

/* .request-box .form-control:focus {
  border-color: #04d39f;
} */

.single-product-edit .entry-summary .form-control {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.modal-login .modal-body .form-control {
  background: #ffffff;
}

.bg-voktum {
  background-size: 100%;
  background-image: url("../assets/images/background/laguna-aciole.webp");
  background-repeat: no-repeat;
  background-position: right top;
}

.btn-round {
  border-radius: 30px;
}

.footer-form {
  margin-top: 80%;
  text-align: center;
}

.input-login {
  border-radius: 50px !important;
}

.bg-white {
  background-color: #f5f5f5;
}

@media (min-width: 768px) {
  .h-md-100 {
    height: 100vh;
  }
}

/********************************
    footer-default
********************************/
footer.site-footer {
  background: #fff;
  -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
}

footer.site-footer .footer-widgets-wrapper {
  padding: 30px 0 35px;
}

footer.site-footer .logo-wrapper {
  text-align: left;
}

footer.site-footer .logo-wrapper img {
  max-width: 200px;
}

footer.site-footer .footer-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #323232;
}

footer.site-footer .widget {
  margin-top: 30px;
}

footer.site-footer .widget ul {
  margin-bottom: 0;
  padding-left: 0;
}

footer.site-footer .widget ul li {
  margin: 5px 0;
  line-height: 30px;
  list-style-type: none;
}

footer.site-footer .widget ul li a {
  color: #969696;
  display: inline-block;
  line-height: 24px;
  font-weight: 400;
  text-transform: capitalize;
}

footer.site-footer .widget ul li:hover a {
  color: var(--sage);
}

footer.site-footer .pgs-social-profiles .social-profiles ul {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 0;
}

footer.site-footer .pgs-social-profiles .social-profiles ul li {
  display: inline-block;
  margin: 0;
  margin-right: 7px;
  margin-bottom: 7px;
  float: left;
  list-style-type: none;
}

footer.site-footer .pgs-social-profiles .social-profiles ul li a i {
  border: 1px solid rgba(173, 173, 173, 0.5);
  font-size: 13px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  vertical-align: top;
  text-align: center;
  color: #969696;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

footer.site-footer .pgs-social-profiles .social-profiles ul li a:hover i {
  background-color: var(--sage);
  border-color: var(--sage);
  color: #fff;
}

footer.site-footer .footer-nav-menu .footer-title {
  margin-bottom: 25px;
  text-transform: capitalize;
}

footer.site-footer .footer-nav-menu ul {
  padding-left: 0;
  margin-bottom: 0;
}

footer.site-footer .footer-nav-menu ul li {
  list-style-type: none;
}

footer.site-footer .footer-nav-menu ul li a {
  position: relative;
  padding: 0;
  padding-left: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

footer.site-footer .footer-nav-menu ul li a:after,
footer.site-footer .footer-nav-menu ul li a:before {
  content: "\f105";
  position: absolute;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

footer.site-footer .footer-nav-menu ul li a:after {
  left: -10px;
  opacity: 0;
}

footer.site-footer .footer-nav-menu ul li a:hover:after {
  left: 0px;
  opacity: 1;
}

footer.site-footer .footer-nav-menu ul li a:hover:before {
  left: 10px;
  opacity: 0;
}

footer.site-footer .pgs-contact-widget ul li i {
  display: table-cell;
  padding-right: 10px;
  min-width: 24px;
  color: var(--sage);
}

footer.site-footer .pgs-contact-widget ul li span {
  display: table-cell;
}

footer.site-footer .pgs-newsletter-widget .newsletter_form {
  position: relative;
}

footer.site-footer .pgs-newsletter-widget .newsletter_form .newsletter-email {
  margin-bottom: 0;
  padding-right: 55px;
  color: #323232;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
  padding: 0 15px;
  height: 46px;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
}

footer.site-footer
  .pgs-newsletter-widget
  .newsletter_form
  .newsletter-email:focus {
  border-color: var(--sage);
}

footer.site-footer
  .pgs-newsletter-widget
  .newsletter_form
  .button-area
  .input-group-btn
  > .btn {
  width: 43px;
  height: 46px;
  position: absolute;
  font-size: 0;
  top: 0;
  right: 0;
  padding: 0;
  line-height: 46px;
  background: var(--sage);
  color: #fff;
  border-radius: 0 2px 2px 0;
  overflow: hidden;
}

footer.site-footer
  .pgs-newsletter-widget
  .newsletter_form
  .button-area
  .input-group-btn
  > .btn:before {
  content: "\f1d9";
  font-family: FontAwesome;
  font-size: 14px;
}

footer.site-footer .pgs-opening-widget .pgs-opening-hours ul li {
  list-style-type: none;
  color: #969696;
  margin: 3px 0;
}

footer.site-footer .pgs-opening-widget .pgs-opening-hours ul li i {
  margin-right: 8px;
  color: var(--sage);
}

footer.site-footer .pgs-opening-widget .pgs-opening-hours ul li span {
  font-weight: 600;
  width: 120px;
  display: inline-block;
}

footer.site-footer .pgs-opening-widget .pgs-opening-hours ul li label {
  margin-bottom: 0;
}

footer.site-footer .pgs-instagram-widget .pgs-instgram-widget .instgram-item {
  width: 29%;
  margin-top: 10px;
  margin-right: 10px;
  float: left;
}

footer.site-footer
  .pgs-instagram-widget
  .pgs-instgram-widget
  .instgram-item:nth-child(-n + 3) {
  margin-top: 0;
}

footer.site-footer .pgs-gallery-widget .pgs-gallery {
  display: inline-block;
  width: 100%;
}

footer.site-footer .pgs-gallery-widget .pgs-gallery .gallery-item {
  border: 1px solid #ddd;
  margin-right: 4%;
  margin-bottom: 4%;
  border-radius: 3px;
  width: 29%;
  float: left;
}

footer.site-footer .pgs-gallery-widget .pgs-gallery .gallery-item img {
  margin-bottom: 0;
  border-radius: 3px;
}

footer.site-footer .pgs-recent-entries .recent-post {
  border-bottom: none;
  margin-bottom: 15px;
  padding-bottom: 0;
  display: table;
  width: 100%;
}

footer.site-footer .pgs-recent-entries .recent-post .recent-post-image {
  width: 65px;
  display: table-cell;
  vertical-align: middle;
  padding-right: 12px;
}

footer.site-footer .pgs-recent-entries .recent-post .recent-post-info {
  display: table-cell;
  vertical-align: middle;
}

footer.site-footer .pgs-recent-entries .recent-post .recent-post-info a {
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  line-height: 20px;
  margin-bottom: 3px;
  display: inline-block;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

footer.site-footer
  .pgs-recent-entries
  .recent-post
  .recent-post-info
  .post-date {
  display: block;
  font-size: 12px;
}

footer.site-footer
  .pgs-recent-entries
  .recent-post
  .recent-post-info
  .post-date
  i {
  margin-right: 7px;
  color: var(--sage);
}

footer.site-footer .pgs-apps-store .apps-store-img .apps-store-item {
  margin-bottom: 3px;
  margin-right: 3px;
  display: inline-block;
}

footer.site-footer .pgs-apps-store .apps-store-img .apps-store-item img {
  width: 140px;
}

footer.site-footer .pgs-products .product-list-widget li {
  padding: 10px 0;
}

footer.site-footer .pgs-products .product-list-widget li a {
  font-weight: 500;
  color: #323232;
  font-size: 13px;
}

footer.site-footer .pgs-products .product-list-widget li a .media img {
  margin-right: 15px;
  margin-left: 0;
  width: 60px;
}

footer.site-footer .pgs-products .product-list-widget li a .media .amount {
  color: var(--sage);
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom {
  padding-bottom: 40px;
  padding-top: 40px;
  border-top: 1px solid rgba(142, 142, 142, 0.2);
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .pgs-social-profiles {
  margin-bottom: 20px;
}

footer.site-footer
  .footer-bottom-wrapper
  .footer-bottom
  .pgs-social-profiles
  .social-profiles
  ul
  li
  a
  i {
  background-color: transparent;
  border: none;
  width: auto;
  height: auto;
}

footer.site-footer
  .footer-bottom-wrapper
  .footer-bottom
  .pgs-social-profiles
  .social-profiles
  ul
  li
  a:hover
  i {
  background-color: none;
  border: none;
  color: var(--sage);
}

footer.site-footer .footer-bottom-wrapper .footer-bottom h5 {
  display: inline-block;
  margin-bottom: 0;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .apps-store-img {
  margin-bottom: 3px;
  margin-right: 3px;
  display: inline-block;
}

footer.site-footer
  .footer-bottom-wrapper
  .footer-bottom
  .pgs-newsletter-widget
  .newsletter_form {
  position: relative;
}

footer.site-footer
  .footer-bottom-wrapper
  .footer-bottom
  .pgs-newsletter-widget
  .newsletter_form
  .button-area
  .input-group-btn
  > .btn.submit {
  width: auto;
  background: none;
  padding: 0 15px;
  padding-left: 25px;
  font-size: 13px;
  font-weight: 600;
  line-height: 46px;
  color: #000000;
  text-transform: uppercase;
  -webkit-border-radius: 0 50px 50px 0;
  -moz-border-radius: 0 50px 50px 0;
  border-radius: 0 50px 50px 0;
}

footer.site-footer
  .footer-bottom-wrapper
  .footer-bottom
  .pgs-newsletter-widget
  .newsletter_form
  .button-area
  .input-group-btn
  > .btn:before {
  margin-right: 14px;
}

footer.site-footer .site-info {
  font-weight: 600;
  font-size: 11px;
  color: #000000;
  text-transform: uppercase;
  background: #f4f1f173;
  padding: 25px 0;
}

footer.site-footer .site-info .footer-widget p {
  margin-bottom: 0;
  color: #000000;
}

footer.site-footer .site-info .footer-widget p a {
  color: #000000;
}

footer.site-footer .site-info .footer-widget p a:hover {
  color: #000000;
}

footer.site-footer .product-tag-cloud .tagcloud a {
  background: 0 0;
  color: #000000;
  margin-bottom: 8px;
  margin-right: 4px;
  display: inline-block;
  border: 1px solid #f2f2f2;
  line-height: 28px;
  padding: 0 12px;
  text-transform: uppercase;
  border-radius: 2px;
}

footer.site-footer .product-tag-cloud .tagcloud a:hover,
footer.site-footer .product-tag-cloud .tagcloud a:focus {
  background: var(--sage);
  color: #fff;
  border-color: var(--sage);
}

footer.site-footer .product-tag-cloud .tagcloud a.tag-cloud-link {
  border: 1px solid rgba(150, 150, 150, 0.25);
  padding: 0 17px;
  font-size: 13px !important;
}

footer.site-footer .footer-align-left {
  text-align: left;
}

footer.site-footer .footer-align-center {
  text-align: center;
}

footer.site-footer .footer-align-center .footer-nav-menu ul li a {
  padding-left: 0;
}

footer.site-footer .footer-align-center .footer-nav-menu ul li a:before,
footer.site-footer .footer-align-center .footer-nav-menu ul li a:after {
  content: "";
}

footer.site-footer .footer-align-center .pgs-contact-widget ul li i {
  display: inline-block;
}

footer.site-footer .footer-align-center .pgs-contact-widget ul li span {
  display: inline-block;
}

footer.site-footer .footer-align-right {
  text-align: right;
}

/********************************
    footer-style-2
********************************/
footer.site-footer.footer-style-2 .footer-widgets {
  text-align: center;
}

footer.site-footer.footer-style-2 .footer-nav-menu ul li {
  padding: 0 10px;
  display: inline-block;
}
.card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50px !important;
}

.btn {
  border-radius: 50px !important;
}

.container-card {
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif !important;
}

.form-control,
input[type="email"],
input[type="password"],
input[type="search"],
input[type="text"],
textarea {
  border-radius: 50px !important;
}

.preloader-modal-cart {
  opacity: 0.6;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden !important;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  padding-top: 20%;
  width: 100%;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.5);
}

#preloader-modal-cart > div {
  height: 100%;
  position: relative;
  width: 100%;
}

#preloader-modal-cart > div svg {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.erro {
  color: #dc3545;
}

.SelectControlado {
  border-radius: 50px !important;
  height: 46px;
}

.imagemRedonda {
  width: 45px !important;
  height: 30px !important;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -3px !important;
  margin-left: -15px !important;
}
.margemNaoErro {
  margin-bottom: 2.5rem;
}

.modal-delete .modal-content .action-button {
  background: #04d39f;
  color: #ffffff;
  padding: 6px 20px;
  line-height: 30px;
  font-size: 13px;
  border-radius: 3px;
}

.modal-delete .modal-content .action-button.no {
  background: #dc3545;
  color: #ffffff;
}

.modal-delete .modal-content {
  padding: 20px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  color: #323232;
}

.btn-card-edit {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 60px;
  background: #04d39f;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  z-index: 9;
}

.btn-card-delete {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 20px;
  background: #dc3545;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  z-index: 9;
}

.product-thumbnail {
  position: relative;
  -ms-flex: 0 0 280px;
  flex: 0 0 280px;
}

.product-thumbnail img {
  width: 100% !important;
  margin-right: 0 !important;
}

.products .product-thumbnail-inner {
  position: relative;
}

.products .product-thumbnail-inner img {
  width: 100%;
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.products .product .product-thumbnail .product-thumbnail-main {
  overflow: hidden;
}

.products .product-inner:hover .product-thumbnail .product-thumbnail-main img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.products .product-inner:hover .product-thumbnail .product-thumbnail-swap {
  opacity: 1;
}

.products .product-inner:hover .product-thumbnail .product-thumbnail-swap img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.products .product .product-thumbnail .product-thumbnail-swap {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.products.list li.product .product-name {
  padding: 0;
  padding-bottom: 5px;
}

.products .product-info .product-name {
  padding-bottom: 0;
  margin: 0;
}

.products .product-info .product-name a {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  display: block;
  color: #323232;
}

/* .products .product-info .product-name a:hover,
.products .product-info .product-name a:focus {
  color: #04d39f;
} */

.products .product_title_type-single_line .product-info .product-name a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.products .product-hover-style-default .product-info .product-name {
  padding-top: 5px;
  margin-bottom: 3px;
}

.products .product-hover-style-icon-top-left .product-info .product-name,
.products .product-hover-style-icon-top-right .product-info .product-name {
  padding: 0;
}

.products .product-hover-style-icon-bottom-right .product-info .product-name {
  padding-top: 5px;
}

.products .product-hover-style-image-center .product-info .product-name {
  padding-top: 5px;
}

.products .product-hover-style-image-left .product-info .product-name {
  padding: 5px 0px;
}

.products
  .product-hover-style-info-transparent-center
  .product-info
  .product-name {
  padding-top: 0.5em;
}

.products
  .product-hover-style-info-transparent-center
  .product-info
  .product-name
  a {
  color: #fff;
}

.products
  .product-hover-style-info-transparent-center
  .product-info
  .product-name
  a:hover {
  color: #04d39f;
}

.products .product-hover-style-minimal .product-info .product-name {
  padding-top: 5px;
}

.products .product-hover-style-minimal-hover-cart .product-name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 7px;
  width: 100%;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin: 0;
}

.products .product-hover-style-minimal-hover-cart .product-name a {
  background: #fff;
  padding: 10px 10px;
  text-align: center;
  font-size: 13px;
  color: #323232;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  display: block;
}

.products .product-hover-style-minimal-hover-cart .product-name a:hover {
  color: #04d39f;
}

.products .product-hover-style-minimal-hover-cart:hover .product-name {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.products .product-hover-style-hover-summary .product-info .product-name {
  padding-top: 3px;
}

.products .product-hover-style-hover-summary .product-info .product-name a {
  font-weight: 600;
}

.products .product-hover-style-info-bottom-bar .product-info .product-name {
  padding-top: 0;
}
.products .product-hover-style-info-bottom .product-info .product-name {
  padding-top: 0;
}

.products .product-hover-style-image-bottom-bar .product-info .product-name {
  padding-top: 0;
}

.products.list li.product-hover-style-info-bottom .product-name,
.products.list li.product-hover-style-info-bottom-bar .product-name {
  padding: 0.5em 0;
}

/* Comecei a partir daqui */

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  display: block;
  color: #000000;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

/*the container must be positioned relative:*/
.autocomplete {
  position: relative;
  display: inline-block;
}

.searchButton {
  position: absolute;
  top: 2px;
  left: 3px;
  width: 40px;
  height: 90%;
  border: 1px solid #fff;
  background: #fff;
  text-align: center;
  color: #323232;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
}

.searchCancelButton {
  position: absolute;
  top: 2px;
  right: 0px;
  width: 40px;
  height: 100%;
  background: transparent;
  text-align: center;
  color: #575757;
  cursor: pointer;
  font-size: 25px;
}

.input-text-search {
  padding: 0 55px !important;
  background: #e7e7e7 !important;
  border: 1px solid #fff;
}

.input-text-search,
input[type="text"] {
  border-radius: 10px !important;
}

.input-text-search:focus,
input[type="text"]:focus {
  border: 1px solid #323232;
  box-shadow: none;
}

.input-text-search::placeholder,
input[type="text"]::placeholder {
  color: #adadad !important;
  font-family: "Helvetica Neue", "Montserrat", sans-serif !important;
}

.caixa-preta {
  background: var(--bg-secundario);
  cursor: pointer;
  padding: 7px;
  border-radius: 6px;
}

.caixa-preta img {
  width: 32px;
  width: 32px;
  margin-top: 1px;
}

:root {
  --bg-primario: #070706;
  --f-cor-primaria: #ffffff;
  --bg-secundario: #141414;
  --f-cinza: #323232;
  --background-site-rgb-value: 249, 249, 249;
  --text-high-contrast-rgb-value: 49, 49, 49;
  --bg-preto: rgba(51, 51, 51, 1);
  --text-body: rgb(222, 222, 222);
  --detail-medium-contrast: rgb(234, 234, 234);
  --text-body: rgb(54, 49, 61);
  --detail-high-contrast: rgb(192, 192, 192);
  --fundo-header: #fff;
  --tamanhoHeader: 50px;
  --margemTopHeader: 20px;
  --tamanhoTotal: calc(var(--margemTopHeader) + var(--tamanhoHeader));
}

/* .inicio::before {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1;
  content: "";  
} */

.inicio {
  margin: 0;
  display: flex;
  background-image: url("../images/fundo_vktum.png");
  background-color: var(--bg-primario);
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.conteudo {
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  color: var(--f-cor-primaria);
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: uppercase;
  z-index: 2;
}

.conteudo img {
  max-width: 807px;
  height: 120px;
}

.conteudo span {
  font-size: 4rem;
  font-weight: 100 !important;
}

.conteudo a {
  display: inline-block;
  text-decoration: none;
  font-family: "Helvetica Neue";
  line-height: 20px;
  font-size: 20px;
  top: 35px;
  color: var(--f-cor-primaria);
}

.link-designer {
  position: absolute;
  left: 45px;
  opacity: 1;
  letter-spacing: 0.18em;
  font-weight: 300;
}

.link-designer-hover {
  position: absolute;
  letter-spacing: 0.14em;
  left: 45px;
  opacity: 0;
  font-weight: 800;
}

.link-produto {
  position: absolute;
  right: 45px;
  opacity: 1;
  letter-spacing: 0.18em !important;
  font-weight: 300 !important;
  line-height: 20px;
}

.link-produto-hover {
  position: absolute;
  font-weight: 900 !important;
  letter-spacing: 0.14em !important;
  right: 45px;
  line-height: 20px;
  opacity: 1;
  visibility: hidden;
}

.contact-wrapper .form-group {
  width: 100%;
}
.form-group .error {
  color: #dc3545;
}

.single-product-edit .entry-summary .form-group label {
  position: relative;
  padding-left: 21px;
  margin-right: 20px;
}

.single-product-edit .entry-summary .form-group label .form-check-input {
  margin-left: 0;
  left: 0;
  margin-top: 5px;
}

.form-group {
  display: flex;
  min-height: 66px;
  align-items: center;
  justify-content: center;
}

.form-group label {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.1em;
  color: #000000;
}

.form-group,
input[type="text"] {
  border-radius: 10px !important;
}

.form-group:focus,
select:focus,
input[type="text"]:focus {
  border: 1px solid #323232 !important;
  box-shadow: none !important;
}

.form-select:focus {
  border: 1px solid #323232 !important;
  box-shadow: 0 0 0 0.1rem #323232 !important;
}

.form-control,
input[type="email"],
input[type="password"],
input[type="search"],
input[type="text"],
select,
textarea {
  /* width: 100%; */
  padding: 10px 15px;
  height: 46px;
  background: #fff;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: --bs-light-text-emphasis;
  font-size: 14px;
}

.form-group::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="search"]::placeholder,
input[type="text"]::placeholder,
textarea::placeholder {
  font-family: "Helvetica Neue", "Montserrat", sans-serif !important;
}

.form-group::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: "Helvetica Neue", "Montserrat", sans-serif !important;
}

.form-group:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: "Helvetica Neue", "Montserrat", sans-serif !important;
}

.linha {
  border-bottom: 1px solid rgba(var(--text-high-contrast-rgb-value), 0.3) !important;
  width: 100%;
}

.texto-carta-nome-produto {
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 3.18px;
  margin-top: 13px;
  width: 90%;
}

.texto-carta-designer-produto {
  font-weight: 400;
  margin-bottom: 13px !important;
  width: 90%;
  text-transform: uppercase;
}

.border-radio-carta-produto {
  border-radius: 20px;
}

.backgroud-rodape-carta-produto {
  background: rgba(24, 24, 24, 0.7);
  padding: 0 5%;
}

.altura-ate-cartas-produto {
  height: 142px;
}

.altura-ate-cartas-produto p {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}

.pointer {
  cursor: pointer;
}


@media screen and (max-width: 1024px) {
  .inicio {
    background-image: none;
    background-color: var(--bg-primario);
    height: 100vh;
    overflow: hidden;
  }
  
  
  .conteudo img {
    max-width: 330px;
  }
  
  .conteudo a {
    font-size: 18px;
  }
  
  .link-produto {
    right: 30px;
  }

  .link-designer {
    left: 30px;
  }

  .link-produto-hover {
    right: 30px;
  }

  .link-designer-hover {
    left: 30px;
  }

  .conteudo span {
    font-size: 3rem;
  }

  .conteudo a {
    top: 25px;
  }

  .caixa-preta {
    padding: 2px 4px;
    height: 30px;
  }

  .caixa-preta img {
    width: 22px;
    height: 22px;
    margin-top: 2px;
  }

  .form-group {
    min-height: 40px;
    display: flex;
  }

  .altura-ate-cartas-produto {
    height: 0%;
    width: 0%;
    visibility: hidden;
  }

  /* .backgroud-rodape-carta-produto {
    border-top: 1px solid rgba(var(--text-high-contrast-rgb-value), 0.3) !important;
    background: transparent;
    width: 90%;
  } */

  /* .texto-carta-nome-produto {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 2.18px;
    margin-top: 25px;
    width: 100%;
  }

  .texto-carta-designer-produto {
    font-weight: 200;
    margin-bottom: 13px !important;
    font-size: 10px;
    width: 100%;
    color: var(--bs-gray-600);
  } */

  .conteudo img {
    height: 100%;
  }

  .autocomplete {
    width: 90%;
  }
  .searchButton{
    background: transparent;
    border: transparent;
  }

  .input-text-search {
    padding: 0 39px;
  }
}
